<template>
  <div>
    <time class="time m-0">
      <span>{{ formatTime(data.actioned_on || data.created_on) }}</span>
      <span>{{ formatDate(data.actioned_on || data.created_on) }}</span>
    </time>

    <div class="icon" :style="getTheme(data.focused_table).bg_color" @click="$emit('filterAuditHistory', 'candidateTransactions')">
      <em :class="getTheme(data.focused_table).src" style="color: white"></em>
    </div>
    <div class="label">
      <h6>{{ formatName(data.actioned_by_user_name) }}</h6>
      <p class="pl-3">
        {{ formatName(data.actioned_by_user_name) }}
        {{ action(data) }} candidate
        {{ getTheme(data.focused_table).record_name }}.
        <em
          v-if="data.action === 'U'"
          v-c-tooltip="'Audit Changes'"
          class="fas fa-circle-info cursor-pointer"
          @click="showRawDataChanges(data)"
        />
      </p>
    </div>
    <CModal
      id="audit-changes"
      title="Audit Changes"
      color="primary"
      :show.sync="showAuditChanges.isShowPopup"
    >
      <template #footer>
        <CButton color="primary" @click="showAuditChanges.isShowPopup = false">
          Close
        </CButton>
      </template>
      <p v-html="showAuditChanges.content"></p>
    </CModal>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "CandidateTransactions",
  props: ["data"],
  data: () => ({
    showAuditChanges: {
      isShowPopup: false,
      content: "",
    },
  }),
  methods: {
    formatTime(data) {
      return data ? this.$moment.utc(data).local().format("hh:mm A") : "--";
    },
    formatDate(data) {
      return data ? this.$moment.utc(data).local().format("DD-MMM-YYYY") : "--";
    },
    formatName(str) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    action(data) {
      if (data.comments == "Candidate Shared") {
        return "shared";
      } else if (data.action == "I") {
        return "added";
      } else {
        return data?.action == "D" ? "deleted" : "updated";
      }
    },
    getTheme(data) {
      let src = "";
      let color = "";
      let record_name = "";
      switch (data) {
        case "candidates":
          src = "fas fa-user-md";
          color = "#50d38a !important";
          record_name = "profile";
          break;
        case "candidate_qualifications":
          src = "fas fa-user-graduate";
          color = "#DC0050;";
          record_name = "qualification";
          break;
        case "candidate_documents":
          src = "fas fa-file-word";
          color = "#1973e8";
          record_name = "document";
          break;
        case "candidate_specialities":
          src = "fas fa-user-tag";
          color = "#61892F;";
          record_name = "specialities";
          break;
        case "candidate_languages":
          src = "fas fa-language";
          color = "#B23850;";
          record_name = "language";
          break;
        case "candidate_pqr_levels":
          src = "fas fa-level-up-alt";
          color = "#A64AC9;";
          record_name = "PQR level";
          break;
        default:
          src = "fas fa-history";
          color = "#A64AC9;";
      }
      return {
        src: src,
        bg_color: "background-color: " + color,
        record_name: record_name,
      };
    },
    showRawDataChanges(data) {
      if (data.action == "U") {
        let display_data = [];
        let updated_data = data?.changed_fields?.changes[data.focused_table];
        Object.entries(updated_data).forEach(([key, value]) => {
          key = key.substring(key.indexOf("[0]['") + 5)?.replaceAll("']", "");
          display_data = [
            ...display_data,
            `<span style='font-weight: 600'>${_.startCase(key)}:</span> ${
              value.new_value
            }`,
          ];
        });
        this.showAuditChanges.content = display_data.join(", ");
      }
      this.showAuditChanges.isShowPopup = true;
    },
  },
};
</script>
