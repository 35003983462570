<template>
  <div class="auditTrail-History has-loading-overlay">
    <LoadingOverlay v-if="isFetchingAuditHistory" />
    <CRow class="d-flex justify-content-start">
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1">History:</label>
        <Select
          name="filter"
          :value="filter.filter"
          @input="handleChangeSelect"
          :options="options && options['filter'] ? options['filter'] : []"
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'communications'"
      >
        <label class="mb-1">Communication Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['communication_type']
              ? options['communication_type']
              : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'comments'"
      >
        <label class="mb-1">Comments Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['comments_type'] ? options['comments_type'] : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'jobApplications'"
      >
        <label class="mb-1">Application Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['job_applications_type']
              ? options['job_applications_type']
              : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1">Date Range:</label>
        <Select
          name="date_range"
          :value="filter.date_range"
          @input="handleChangeSelect"
          :options="
            options && options['date_range'] ? options['date_range'] : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.date_range && filter.date_range.code == 'custom_date'"
      >
        <label class="mb-1">Custom Date:</label>
        <DatePicker
          name="custom_date"
          :value="filter.custom_date"
          @input="handleDatePickerChange"
          :showTillToday="true"
          :range="true"
          returnType="format"
        />
      </CCol>
    </CRow>
    <div class="d-flex justify-content-end col-11 mb-2" v-if="isIconFilter">
      <CButton 
        class="btn-primary" 
        v-c-tooltip="'Clear Filters'"
        @click="clearFilterByIcon" >
        Clear
      </CButton>
    </div>
    <div class="container timeline-scrolling" id="history-info">
      <div class="row">
        <div
          class="col-md-11"
          v-if="
            filter.filter && filter.filter.code == 'filteredCandidateAuditView'
          "
        >
          <ul class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <CandidateTransactions
                v-if="data.audit_type == 'profile_changes'"
                :data="data"
                @filterAuditHistory="filterAuditHistoryByIcon"
              />
              <StatusChange
                :data="data"
                v-if="data.audit_type == 'status_change'"
                @moreToggle="moreToggle(data, index)"
                @filterAuditHistory="filterAuditHistoryByIcon"
              />
              <Comments
                :data="data"
                v-if="
                  data.audit_type == 'private_comments' ||
                  data.audit_type == 'candidate_related_comments'
                "
                @moreToggle="moreToggle(data, index)"
              />
              <Communications
                v-if="data.audit_type == 'communications'"
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
              <JobApplication
                v-if="data.audit_type == 'job_applications'"
                :data="data"
                @moreToggle="moreToggle(data, index)"
                @filterAuditHistory="filterAuditHistoryByIcon"
              />
            </li>
          </ul>

          <h5
            class="text-center"
            v-if="!(getAuditHistory && getAuditHistory.length)"
          >
            No Data Found!
          </h5>
        </div>
        <div class="col-md-11" v-else>
          <ul
            v-if="filter.filter.code == 'candidateTransactions'"
            class="timeline pt-3"
          >
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <CandidateTransactions :data="data" />
            </li>
          </ul>

          <ul v-if="filter.filter.code == 'statusChange'" class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <StatusChange
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
            </li>
          </ul>

          <ul v-if="filter.filter.code == 'comments'" class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <Comments :data="data" @moreToggle="moreToggle(data, index)" />
            </li>
          </ul>

          <ul
            v-if="filter.filter.code == 'communications'"
            class="timeline pt-3"
          >
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <Communications
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
            </li>
          </ul>

          <ul
            v-if="filter.filter.code == 'jobApplications'"
            class="timeline pt-3"
          >
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <JobApplication
                :data="data"
                @moreToggle="moreToggle(data, index)"
              />
            </li>
          </ul>

          <h5
            class="text-center"
            v-if="!(getAuditHistory && getAuditHistory.length)"
          >
            No Data Found!
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import CandidateTransactions from "./CandidateTransactions";
import StatusChange from "./StatusChange";
import Comments from "./Comments";
import Communications from "./Communications";
import JobApplication from "./JobApplication.vue";

export default {
  name: "HistoryInfo",
  props: ["candidateProfile"],
  components: {
    Select,
    DatePicker,
    CandidateTransactions,
    StatusChange,
    Comments,
    Communications,
    JobApplication,
  },
  data: () => ({
    filter: {
      filter: { label: "All", code: "filteredCandidateAuditView" },
      date_range: { label: "Last 3 months", code: 91 },
      sub_filter: null,
      isIconFilter: false,
    },
  }),
  computed: {
    ...mapGetters([
      "isFetchingAuditHistory",
      "getAuditHistory",
      "getAuditHistoryPagination",
      "getContactMethods",
    ]),
    options() {
      return {
        filter: [
          { label: "All", code: "filteredCandidateAuditView" },
          { label: "Profile Updates", code: "candidateTransactions" },
          { label: "Status Changes", code: "statusChange" },
          { label: "Job Application/Withdrawal", code: "jobApplications" },
          { label: "Comments", code: "comments" },
          { label: "Communications", code: "communications" },
        ],
        communication_type: this.getContactMethods || [],
        comments_type: [
          { label: "Private Comments", code: "privateCandidateComments" },
          { label: "Candidate Related Comments", code: "comments" },
        ],
        job_applications_type: [
          { label: "All", code: 0 },
          { label: "Applied", code: 78 },
          { label: "Withdrawn", code: 80 },
          { label: "System Rejected", code: 79 },
        ],
        date_range: [
          { label: "Last 7 days", code: 7 },
          { label: "Last 14 days", code: 14 },
          { label: "Last 6 weeks", code: 42 },
          { label: "Last 3 months", code: 91 },
          { label: "Last 6 months", code: 182 },
          { label: "Last 12 months", code: 365 },
          { label: "Custom date", code: "custom_date" },
        ],
      };
    },
  },
  beforeDestroy() {
    const ele = document.getElementById("history-info");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  watch: {
    "$route.params.id": function (id) {
      this.filter.filter = {
        label: "Profile Updates",
        code: "candidateTransactions",
      };
      this.fetchCandidateAuditHistory(false);
    },
  },
  methods: {
    ...mapActions([
      "fetchContactMethods",
      "fetchAuditHistory",
      "setCandidateListBackUrl",
      "downloadAttachment",
    ]),
    ...mapMutations(["RESET_AUDIT_HISTORY"]),
    handleChangeSelect(name, value) {
      Vue.set(this.filter, name, value);
      if (name == "filter") {
        this.filter.sub_filter = null;
        if (value?.code == "communications" || value?.code == "comments") {
          this.$store.commit("RESET_AUDIT_HISTORY");
          return;
        }
        if (value?.code == "jobApplications") {
          this.filter.sub_filter = { label: "All", code: 0 };
        }
      }
      this.fetchCandidateAuditHistory(false);
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.filter, name, value);
      this.fetchCandidateAuditHistory(false);
    },
    moreToggle(data, index) {
      this.$set(this.getAuditHistory[index], "_toggled", !data._toggled);
    },
    fetchCandidateAuditHistory(pagination) {
      if (this.filter?.date_range?.code != "custom_date") {
        this.filter.custom_date = [
          this.$moment()
            .subtract(this.filter?.date_range?.code, "days")
            .format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD"),
        ];
      }
      let payload = {
        candidate_uid: this.candidateProfile?.candidate_uid,
        customer_uid: this.candidateProfile?.customer_uid,
        serviceUrl: this.filter?.filter?.code,
        pagination: pagination,
        from_date: this.filter?.custom_date[0],
        to_date: this.filter?.custom_date[1],
      };
      if (
        this.filter?.filter?.code == "communications" &&
        this.filter?.sub_filter?.code != "email"
      )
        payload = {
          ...payload,
          contact_method: this.filter?.sub_filter?.code,
        };
      if (this.filter?.sub_filter?.code == "email") {
        payload = {
          ...payload,
          serviceUrl: "candidateEmailCommunication",
        };
      }
      if (this.filter?.filter?.code == "filteredCandidateAuditView") {
        payload = {
          ...payload,
          date_field: "created_on",
        };
      }
      if (
        this.filter?.filter?.code == "jobApplications" &&
        this.filter?.sub_filter?.code
      ) {
        payload = {
          ...payload,
          status_id: this.filter?.sub_filter?.code,
        };
      }
      if (this.filter?.filter?.code == "comments") {
        payload.serviceUrl = this.filter?.sub_filter?.code;
      }
      this.fetchAuditHistory(payload);
    },
    onBodyScroll(e) {
      if (
        this.isFetchingAuditHistory ||
        this.getAuditHistoryPagination?.noMoreData
      )
        return;
      if (
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 1
      ) {
        this.fetchCandidateAuditHistory(true);
      }
    },
    filterAuditHistoryByIcon(data) {
      this.isIconFilter = true;
      const index = this.options?.filter?.findIndex(option => option.code === data);
      if (index !== -1) {
        const { code } = this.options.filter[index];
        this.handleChangeSelect('filter', this.options.filter[index]);
        if (code === 'jobApplications') {
          this.handleChangeSelect('filter.sub_filter', this.options.job_applications_type[0]);
        }
      }
    },
    clearFilterByIcon() {
      this.isIconFilter = false;
      this.handleChangeSelect('filter',this.options.filter[0])
    }
  },
  mounted() {
    if (!this.getContactMethods?.length) {
      this.fetchContactMethods();
    }
    this.fetchCandidateAuditHistory(false);
    const ele = document.getElementById("history-info");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
