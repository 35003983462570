<template>
  <div>
    <time class="time m-0">
      <span>{{ formatTime(data.created_on) }}</span>
      <span>{{ formatDate(data.created_on) }}</span>
    </time>

    <div class="icon" style="background-color: #dc0050 !important" @click="$emit('filterAuditHistory','statusChange');">
      <em class="fas fa-user-md" style="color: white"></em>
    </div>
    <div class="label">
      <p class="h6" v-if="data.comments">
        <span v-if="!data._toggled">
          {{ data.comments.substring(0, 60)
          }}{{ data.comments.substring(61) ? "..." : "" }}
        </span>
        <span v-else style="line-height: normal">{{ data.comments }}</span>
        <span>
          <CButton
            v-if="data.comments.substring(61)"
            @click="toggle()"
            class="btn btn-link p-0"
          >
            {{ data._toggled ? "Show less" : "Show more" }}
          </CButton>
        </span>
      </p>
      <p
        class="mb-2"
        v-if="
          data.sub_status == 'Favourite' ||
          data.previous_sub_status == 'Favourite'
        "
      >
        {{ data.sub_status == "Favourite" ? "Marked as" : "Removed from" }}
        <strong> Favourite </strong>
        for Job
        <a
          class="h6"
          href="javascript:void(0)"
          @click="navigateToJobListPreview(data.job_id)"
        >
          {{ data.job_display_uid }} | {{ formatName(data.job_title) }}
        </a>
        by
        <strong>
          {{ formatName(data.created_user || data.commented_by) }}
        </strong>
      </p>
      <p class="pl-3 mb-2" v-else>
        Moved
        {{ data.previous_status ? `from ` : "" }}
        <strong>
          {{ data.previous_status
          }}{{
            data.previous_sub_status ? ` (${data.previous_sub_status}) ` : " "
          }}
        </strong>
        to
        <strong>
          {{ data.status }}{{ data.sub_status ? ` (${data.sub_status})` : "" }}
        </strong>
        for Job
        <a
          class="h6"
          href="javascript:void(0)"
          @click="navigateToJobListPreview(data.job_id)"
        >
          {{ data.job_display_uid }} | {{ formatName(data.job_title) }}
        </a>
        by
        <strong>
          {{ formatName(data.created_user || data.commented_by) }}
        </strong>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusChange",
  props: ["data", "moreToggle"],
  methods: {
    formatTime(data) {
      return data ? this.$moment.utc(data).local().format("hh:mm A") : "--";
    },
    formatDate(data) {
      return data ? this.$moment.utc(data).local().format("DD-MMM-YYYY") : "--";
    },
    formatName(str) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    toggle() {
      this.$emit("moreToggle");
    },
    navigateToJobListPreview(job_id) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push({ path: `/job-list-preview/${job_id}` });
    },
  },
};
</script>
