<template>
  <div>
    <time class="time m-0">
      <span>{{ formatTime(data.created_on) }}</span>
      <span>{{ formatDate(data.created_on) }}</span>
    </time>

    <div class="icon" style="background-color: #1973e8">
      <em class="fas fa-comment" style="color: white"></em>
    </div>
    <div class="label">
      <p class="h6" v-if="data.comments">
        <span v-if="!data._toggled">
          {{ data.comments.substring(0, 60)
          }}{{ data.comments.substring(61) ? "..." : "" }}
        </span>
        <span v-else style="line-height: normal">{{ data.comments }}</span>
        <span>
          <CButton
            v-if="data.comments.substring(61)"
            @click="toggle()"
            class="btn btn-link p-0"
          >
            {{ data._toggled ? "Show less" : "Show more" }}
          </CButton>
        </span>
      </p>
      <p class="pl-3 mb-2">
        Updated by
        <strong>
          {{ formatName(data.created_user || data.commented_by) }}
        </strong>
        <span v-if="data.job_id">
          for Job
          <a
            class="h6"
            href="javascript:void(0)"
            @click="navigateToJobListPreview(data.job_id)"
          >
            {{ data.job_display_uid }} | {{ formatName(data.job_title) }}
          </a>
        </span>
        .
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Comments",
  props: ["data", "moreToggle"],
  methods: {
    formatTime(data) {
      return data ? this.$moment.utc(data).local().format("hh:mm A") : "--";
    },
    formatDate(data) {
      return data ? this.$moment.utc(data).local().format("DD-MMM-YYYY") : "--";
    },
    formatName(str) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    toggle() {
      this.$emit("moreToggle");
    },
    navigateToJobListPreview(job_id) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push({ path: `/job-list-preview/${job_id}` });
    },
  },
};
</script>
